<span class="sentry-container {{site ? 'sentry-site' : 'sentry-platform'}} {{platform != 'common' && platform != 'core' && (!site || env.totalEvents != 0) ? 'sentry-visible' : 'sentry-hidden'}}">

  <span class="label">
    <!--<img class="avatar-22" src="assets/{{platform}}.png" *ngIf="!site && mode != 'simple'">-->
    <span class="issue-total {{env.issues.length == 0 ? 'normal' : 'error'}}"><a href="https://keyman.sentry.io/issues/?project={{projectIndex()}}" target="_blank">{{env.issues.length}}</a></span>
    <span class="event-total {{env.issues.length == 0 ? 'normal' : 'error'}}" *ngIf="mode != 'simple'"><a href="https://keyman.sentry.io/issues/?project={{projectIndex()}}" target="_blank">{{env.totalEvents}}</a></span>
  </span>

  <div class="sentry-detail {{pinned ? 'pinned':''}} gravity-{{gravityX}} gravity-{{gravityY}}" #wrapper>
    <div class="summary">
      <span class="pin" (click)="pin()">📌</span>
      <span><app-clipboard [text]="getSentryIssueText()" title="Copy HTML formatted list of sentry issues to clipboard"></app-clipboard></span>
      <span>
        <span class="summary-environment">{{environment}}</span>
        <span class="summary-label">Issues</span><span>{{env.issues.length}}</span>
        <span class="summary-label">Events</span><span>{{env.totalEvents}}</span>
        <span class="summary-label">Users</span><span>{{env.totalUsers}}</span>
      </span>
      <div class='clear'></div>
    </div>
    <ul class="issues" *ngIf="pinned || (isVisible | async)">
      <li *ngFor="let issue of env.issues; trackBy: issueTrackBy">
        <a href="{{issue.permalink}}" target="_blank">
          <b>{{issue.shortId}}</b>
          <span class="issue-title">{{issue.title}}</span>
        </a>
        <span class="label">
          <span class="issue-count normal" title="# events"><span>{{issue.count}}</span></span>
          <span class="issue-users normal" title="# users"><span>{{issue.userCount}}</span></span>
        </span>

        <span *ngFor="let annotation of issue.annotations">
          <a href="{{extractAnnotationLink(annotation)}}" target="_blank">🔗 #{{extractAnnotationIssueNumber(annotation)}}</a>
        </span>
      </li>
    </ul>
  </div>
</span>