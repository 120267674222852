<table class="table table-striped table-bordered issue-view-{{issueView}} pull-request-view-{{pullRequestView}} {{isBetaRunning() ? 'in-beta' : 'not-in-beta'}}">
  <thead class='thead-dark'>
    <tr>
      <th id='th-platform'>Platform</th>
      <th id='th-stable' class='tier'>Stable</th>
      <th id='th-beta' class='tier'>Beta</th>
      <th id='th-alpha' class='tier'>Alpha</th>
      <th id='th-issues'>Issues
        <span class='view-link issue-view-link'>
          View:
          <a id='issue-view-link-current' href="javascript:void(0)" (click)="setIssueView('current')">Current</a>
          <a id='issue-view-link-all' href="javascript:void(0)" (click)="setIssueView('all')">All</a>
        </span>
        <app-clipboard [text]="clipboardAllIssues" title="Copy HTML formatted list of all current issues to clipboard"></app-clipboard>
      </th>
      <th id='th-pulls'>Pull Requests
        <app-clipboard [text]="clipboardAllPullRequests" title="Copy HTML formatted list of all current PRs to clipboard"></app-clipboard>
        <span class='view-link pull-request-view-link'>
          View by:
          <a id='pull-request-view-link-platform' href="javascript:void(0)" (click)="setPRView('platform')">Platform</a>
          <a id='pull-request-view-link-project' href="javascript:void(0)" (click)="setPRView('project')">Project</a>
          <a id='pull-request-view-link-status' href="javascript:void(0)" (click)="setPRView('status')">Status</a>
          <a id='pull-request-view-link-author' href="javascript:void(0)" (click)="setPRView('author')">Author</a>
          <a id='pull-request-view-link-base' href="javascript:void(0)" (click)="setPRView('base')">Base</a>
        </span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class='tr-platform' *ngFor="let platform of platforms | keyvalue">
      <th>
        <div *ngIf="!showCodeOwners">
          <img src="assets/{{platform.value.id}}.png">&#160;&#160;
          <a href="https://github.com/keymanapp/keyman/pulls?q=is%3Aopen+is%3Apr+label%3A{{platform.value.id}}/" target="_blank">{{platform.value.name}}</a>
          <span class="new-issue-links">
            <a title="Create new bug report issue for {{platform.value.name}}" href="https://github.com/keymanapp/keyman/issues/new?assignees=&labels=bug,{{platform.value.id}}/&template=bug_report.md&title=bug({{platform.value.id}})%3A+" target="_blank">🐛</a>
            <a title="Create new feature request issue for {{platform.value.name}}" href="https://github.com/keymanapp/keyman/issues/new?assignees=&labels=feat,{{platform.value.id}}/&template=feature_request.md&title=feat({{platform.value.id}})%3A+" target="_blank">➕</a>
          </span>
        </div>
        <div *ngIf="showCodeOwners">
          <div class="platform-row">
            <div class="platform-icon">
              <a href="https://github.com/keymanapp/keyman/pulls?q=is%3Aopen+is%3Apr+label%3A{{platform.value.id}}/" target="_blank"><img src="assets/{{platform.value.id}}.png"></a>
            </div>
            <div class="platform-owner" *ngIf="status.codeOwners[platform.value.id]">
              <img class="avatar-22" src="{{getAvatar(status.codeOwners[platform.value.id].owner)}}" title="Platform owner: @{{status.codeOwners[platform.value.id].owner}}">
            </div>
            <div class="platform-advocate" *ngIf="status.codeOwners[platform.value.id] && showCodeOwners">
              <img class="avatar-22" src="{{getAvatar(status.codeOwners[platform.value.id].advocate)}}" title="Platform advocate: @{{status.codeOwners[platform.value.id].advocate}}">
            </div>
          </div>
          <!--div class="platform-row">
            <div class="platform-title">
              <a href="https://github.com/keymanapp/keyman/pulls?q=is%3Aopen+is%3Apr+label%3A{{platform.value.id}}/" target="_blank">{{platform.value.name}}</a>
            </div>
          </div>-->
        </div>
      </th>

      <td class="tier" id="tier-stable">
        <app-platform-tier-box [platforms]="platforms" [platform]="platform.value" [status]="status" [changeCounter]="changeCounter" [tier]="'stable'"></app-platform-tier-box>
      </td>

      <td class="tier" id="tier-beta">
        <app-platform-tier-box [platforms]="platforms" [platform]="platform.value" [status]="status" [changeCounter]="changeCounter" [tier]="'beta'"></app-platform-tier-box>
      </td>

      <td class="tier" id="tier-alpha">
        <app-platform-tier-box [platforms]="platforms" [platform]="platform.value" [status]="status" [changeCounter]="changeCounter" [tier]="'alpha'"></app-platform-tier-box>
      </td>

      <td class="issues">
        <div class="issue-container">
          <div class="issue-count-container">
            <a class="issue-count" target="_blank" href="https://github.com/keymanapp/keyman/issues?q=is%3Aopen+is%3Aissue+label%3A{{platform.value.id}}/">{{platform.value.totalIssueCount}}</a>
          </div>
          <div class="issue-box-container">
            <ng-container *ngFor="let milestone of platform.value.milestones">
              <app-issue-list [view]="issueView" [gravityX]="'left'" [gravityY]="(platform.value.id == 'developer' ? 'top' : 'bottom')" [repo]="'keyman'" [milestone]="milestone" [platform]="platform" [issues]="milestone?.nodes"></app-issue-list>
            </ng-container>
          </div>
        </div>
      </td>

      <td class="pulls" *ngIf="pullRequestView == 'status' && platform.value.id == 'android'" rowspan='9'>
        <app-box-pr-status
          [status]="status" [userTestIssues]="userTestIssues" [userTestIssuesPassed]="userTestIssuesPassed"
          [pullsByStatus]="pullsByStatus" [changeCounter]="changeCounter" [pullStatusName]="pullStatusName">
        </app-box-pr-status>
      </td>

      <td class="pulls" *ngIf="pullRequestView == 'project' && platform.value.id == 'android'" rowspan='9'>
        <app-box-pr-project [status]="status" [pullsByProject]="pullsByProject" [changeCounter]="changeCounter"></app-box-pr-project>
      </td>

      <td class="pulls" *ngIf="pullRequestView == 'author' && platform.value.id == 'android'" rowspan='9'>
        <app-box-pr-author [status]="status" [pullsByAuthor]="pullsByAuthor" [changeCounter]="changeCounter"></app-box-pr-author>
      </td>

      <td class="pulls" *ngIf="pullRequestView == 'platform'">
        <app-box-pr-platform [status]="status" [platform]="platform" [changeCounter]="changeCounter"></app-box-pr-platform>
      </td>

      <td class="pulls" *ngIf="pullRequestView == 'base' && platform.value.id == 'android'" rowspan="9">
        <app-box-pr-base [status]="status" [pullsByBase]="pullsByBase" [changeCounter]="changeCounter"></app-box-pr-base>
      </td>
    </tr>

  </tbody>
</table>
<table id="sites-and-relatives" class="table table-striped table-bordered table-sm issue-view-{{issueView}}">
  <tbody>
    <tr class='tr-site'>
      <th class='th-keyboards'><a href="https://github.com/keymanapp/keyboards" target="_blank">⌨ keyboards</a></th>
      <th class='th-lexical-models'><a href="https://github.com/keymanapp/lexical-models" target="_blank">📔 lexical-models</a></th>
      <th class='gap'></th>
      <th class='th-discourse'><a href="https://community.software.sil.org/c/keyman" target="_blank">🗨 community</a></th>
      <th class='gap'></th>
      <th *ngFor="let site of sites | keyvalue">
        <a target="_blank" href="https://{{site.key}}/_control/info">
          <span *ngIf="site.value.hasState" class='liveliness liveliness-{{getSiteLivelinessClass(site.key)}}'></span>
        </a>
        <span *ngIf="!site.value.hasState" class='liveliness liveliness-unknown'></span>
        <a target="_blank" href='https://github.com/keymanapp/{{site.key}}'>{{site.value.id == 'keymanweb' ? 'kmw' : site.value.id}}</a>
        <app-sentry [environment]="'production'" [gravityX]="site.key &lt; 'help' ? 'right' : 'left'" [gravityY]="'top'" [site]="site.key" [platform]="site.key" [issues]="status?.sentryIssues[site.key]"></app-sentry>
      </th>
      <th class='gap'></th>
      <th id='other-repos-cell'>
        <span id='other-repos-title'>other repos</span>
        <div id='other-repos'>
          <ul>
            <li *ngFor="let repo of otherSites?.repos">
              <a target="_blank" href='https://github.com/keymanapp/{{repo}}'>{{repo}}</a>
            </li>
          </ul>
        </div>
      </th>
    </tr>
    <tr class='tr-site'>
      <td class='pulls'>
        <app-pull-request-list
          [status]="status"
          [view]="'all'"
          [gravityX]="'right'"
          [gravityY]="'top'"
          [repo]="'keyboards'"
          [milestone]="{title:'Open PRs',count:keyboardPRs?.length}"
          [pullRequests]="keyboardPRs"></app-pull-request-list>
      </td>
      <td class='pulls'>
        <app-pull-request-list
        [status]="status"
          [view]="'all'"
          [gravityX]="'right'"
          [gravityY]="'top'"
          [repo]="'lexical-models'"
          [milestone]="{title:'Open PRs',count:lexicalModelPRs?.length}"
          [pullRequests]="lexicalModelPRs">
        </app-pull-request-list>
      </td>
      <td class='gap'></td>
      <td class="community">
        <app-community-queue [queue]="status.communitySiteQueue"></app-community-queue>
      </td>
      <td class='gap'></td>
      <ng-container *ngFor="let site of sites | keyvalue">
      <td class='pulls'>
        <span *ngFor="let pull of site.value.pulls">
          <app-pull-request [status]="status" [scope]="'site'" [gravityX]="site.key &lt; 'help' ? 'right' : 'left'" [gravityY]="'top'" [class]="" [pull]="pull"></app-pull-request>
        </span>
      </td>
      </ng-container>
      <td class='gap'></td>
      <td class='pulls'>
        <span *ngFor="let pull of otherSites?.pulls">
          <app-pull-request [status]="status" [scope]="'site'" [gravityX]="'left'" [gravityY]="'top'" [class]="" [pull]="pull"></app-pull-request>
        </span>
      </td>
    </tr>
    <tr class='tr-site'>
      <td class='issues'>
        <app-issue-list [view]="'all'" [gravityX]="'right'" [gravityY]="'top'" [repo]="'keyboards'" [milestone]="{title:'Open issues',count:keyboardIssues.length}" [issues]="keyboardIssues"></app-issue-list>
      </td>
      <td class='issues'>
        <app-issue-list [view]="'all'" [gravityX]="'right'" [gravityY]="'top'" [repo]="'lexical-models'" [milestone]="{title:'Open issues',count:lexicalModelIssues.length}" [issues]="lexicalModelIssues"></app-issue-list>
      </td>
      <td class='gap'></td>
      <td class="community">
        <!-- <app-community-queue></app-community-queue> -->
      </td>
      <td class='gap'></td>
      <ng-container *ngFor="let site of sites | keyvalue">
        <td class='issues'>
          <span class="new-issue-links">
            <a title="Create new issue for {{site.key}}" href="https://github.com/keymanapp/{{site.key}}/issues/new" target="_blank">➕</a>
          </span>
          <ng-container *ngFor="let milestone of site.value.milestones">
            <app-issue-list [view]="issueView" [gravityX]="site.key &lt; 'help' ? 'right' : 'left'" [gravityY]="'top'" [repo]="site.key" [milestone]="milestone" [issues]="milestone?.nodes"></app-issue-list>
          </ng-container>
        </td>
      </ng-container>
      <td class='gap'></td>
      <td class='issues'>
        <ng-container *ngFor="let milestone of otherSites?.milestones">
          <app-issue-list [view]="issueView" [gravityX]="'left'" [gravityY]="'top'" [repo]="'*'" [milestone]="milestone" [issues]="milestone?.nodes"></app-issue-list>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
